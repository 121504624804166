/* ContactUs.css */

/* Container styles */
.contact-us-container {
    text-align: center;
    padding: 5vh;
    background-color: #393e46; /* Dark background color */
    color: #fff; /* White text color */
  }
  
  /* Header styles */
  .contact-us-header {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  /* Contact form styles */
  .contact-form {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-size: 18px;
    margin-bottom: 8px;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #61dafb; /* Blue border color */
    border-radius: 5px;
  }
  
  textarea {
    resize: vertical;
  }
  
  .submit-button {
    background-color: #61dafb; /* Blue color */
    color: #fff;
    font-size: 18px;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Hover effect for submit button */
  .submit-button:hover {
    background-color: #00a0ff; /* Lighter blue on hover */
  }