body,
html {
  /* font-family: "Figtree",sans-serif; */
  font-family: Sora,Helvetica,sans-serif;
  height: 100%;
  margin: 0;
  color: #32236f;
  font-size: 16px;
  font-family: Sora,Helvetica,sans-serif;
  font-weight: 400;
  opacity: 1;
  background-color: #f6f4fe;
}

button:hover {
  cursor: pointer;
}

.wrap {
    box-sizing: content-box;
    margin: 0 auto;
    padding: 0 10px;
    max-width: 1228px;
  }

.title-area {
  margin-bottom: 54px;
}

.title {
  font-size: 40px;
  line-height: 43px;
  font-weight: 700;
}

.full-center {
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
}

.fade-left {
  margin-bottom: 5px;
}

ul li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
