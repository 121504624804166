/* PhotoCollection.css */

/* Container styles */
.photo-collection-container {
  text-align: center;
  padding: 5vh;
  background-color: #fff; 
  color: #333; 
}

/* Header styles */
.photo-collection-header {
  font-size: 36px;
  margin-bottom: 20px;
}

/* Photo grid styles */
.photo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

/* Photo styles */
.photo {
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
}