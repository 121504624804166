/* ComingSoon.css */
.coming-soon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .coming-soon-title {
    font-size: 48px;
    color: #654ae9;
    margin-bottom: 20px;
  }
  
  .coming-soon-description {
    font-size: 18px;
    color: #888;
  }