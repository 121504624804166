/* CustomerReviews.css */

/* Container styles */
.customer-reviews-container {
    text-align: center;
    padding: 5vh;
    background-color: #393e46; /* Dark background color */
    color: #fff; /* White text color */
  }
  
  /* Header styles */
  .customer-reviews-header {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  /* Reviews styles */
  .reviews {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .review {
    max-width: 400px;
    margin: 20px;
    padding: 20px;
    background-color: #232931; /* Dark background color */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
  }
  
  .comment {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .name {
    font-size: 16px;
    font-style: italic;
  }