.how-it-works-section {
    background: #f6f4fe;
    text-align: center;
    padding: 5vh;
}

.how-it-works-inner {
    background-color: #FFFFFFFF;
    padding: 70px 0 100px;
    border-radius: 30px;
    box-shadow: 0 4px 30px #ede9fe;
}

.how-it-works-header-container {
    margin: 32px 0 64px 0;
}

.how-it-works-header-title {
    color: #654ae9;
}

.how-it-works-body-container {
    margin: 0;
}

.how-it-works-body-container ul li:first-child:before {
    top: 0;
}

.how-it-works-body-container ul li:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50px;
    transform: translate(-50%);
    width: 4px;
    height: calc(100% + 100px);
    background-color: #dfdaf3;
}

.how-it-works-body-container ul li:first-child:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%);
    width: 14px;
    height: 14px;
    background-color: #dfdaf3;
    border-radius: 15px;
}

.how-it-works-body-container ul li:last-child:before {
    height: 50%;
    top: 0;
}

/* *, :after, :before {
    box-sizing: border-box;
} */

.how-it-works-body-container ul {
    max-width: 1080px;
    margin: 0 auto;
    padding: 10px;
}

.how-it-works-body-container ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 50px;
}

.how-it-works-body-container li:nth-child(2) .step-text {
    text-align: left;
}

.step-text {
    width: 360px;
    text-align: right;
}

.step-text h4 {
    font-size: 20px;
    font-weight: 600;
}

.step-number {
    background-image: url('../assets/icon_bg-step.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.step-number h3 {
    font-size: 30px;
    font-weight: 600;
}

.step-img {
    width: 360px;
    text-align: right;
}

.step-img img {
    max-width: 100%;
}