body,
html {
  font-family: "Figtree",sans-serif;
  height: 100%;
  margin: 0;
}

.combine-component {
  background: url('../assets/bg-2.jpeg') center/cover repeat-x;
  animation: moveBackground 30s linear infinite;
  background-size: cover;
  background-position: center;
  height: 125vh; /* Adjusted to use viewport height */
  display: flex;
  flex-direction: column;
}

@keyframes moveBackground {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
