
/* MainContent.css */
.main-content {
  max-width: 1128px;
  margin: 5% auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: transparent;
  padding: 20px;
  border-radius: 10px;
}

.left-column,
.right-column {
  flex-basis: 100%; /* Full width on small screens */
  color: #fff;
  padding: 10px;
  text-align: left;
  text-shadow: 1px 1px 1px rgba(0,0,0,.25), 2px 2px 1px rgba(0,0,0,.25);
}

/* Add a media query to float the right column underneath the left column on larger screens */
@media screen and (min-width: 768px) {
  .left-column,
  .right-column {
    flex-basis: 48%; /* Adjust as needed */
  }
}

.check-icon {
  margin-right: 10px;
  font-size: 1rem;
  color: #ffffff; 
}

.main-content-header-text {
  text-transform: initial;
  font-size: 70px; /* Adjusted font size */
  font-weight: bold;
  margin-bottom: 10px; /* Adjusted margin */
  margin-top: 0;
  line-height: 1;
}

.feature {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding-left: 40px;
  color: #fff;
}

.feature:hover {
  cursor: pointer;
  color: #ff4081; /* Highlight feature on hover */
}

p {
  font-size: 1.15rem; /* Adjust the font size as needed */
  padding: 0px;
}

.right-column {
  color: #fff;
  margin-bottom: 20px; /* Add margin between the columns */
  position: relative;
}

.email-box {
  position: absolute;
  top: 35%;
  left: 10%;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  z-index: 1;

  /* Add styling for an attractive encasing */
  background-color: rgba(255, 255, 255, 0.8); /* Off-white background color with some transparency */
  border-radius: 10px; /* Adjust border-radius as needed for rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Soft box-shadow for a subtle effect */
}

input,
button {
  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box; /* Include padding and border in the width */
  border-radius: 10px; /* Add border-radius for rounded corners */
}

/* Button styles */
.email-box button {
  width: 100%; /* Make the input and button full width */
  /* Inherit button styles from HeaderNavigation.css */
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ff4081; /* Pink color for the button */
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition effect */
}

.email-box button:hover {
  background-color: #e10050; /* Darker pink color on hover */
}
