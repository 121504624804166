/* HeaderNavigation.css */

/* Container styles */
.header-container {
  background: transparent;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5%;
  padding-left: 5%;
}

/* Logo container styles */
.logo-container {
  display: flex;
  align-items: center;
}

/* Logo styles */
.logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

/* Header text styles */
.header-text {
  font-size: 24px;
  margin: 0;
}

/* Navigation styles */
.navigation {
  display: flex;
  align-items: center;
}

/* Label styles */
.label {
  margin-right: 20px;
  font-size: 16px;
  color: #ddd; /* Light gray color for labels */
}

/* Button styles */
.button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ff4081; /* Pink color for the button */
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition effect */
}
.button:hover {
  background-color: #e10050; /* Darker pink color on hover */
} 

/* Navigation link styles */
.nav-link {
  margin-right: 20px;
  font-size: 16px;
  color: #fff; /* White color for navigation links */
  text-decoration: none; /* Remove underline from links */
  transition: color 0.3s ease; /* Smooth transition effect */
}

.nav-link:hover {
  color: #ff4081; /* Pink color on hover */
}
