/* FeaturesCarousel.css */

/* Container styles */
.features-carousel-container {
  text-align: center;
  padding: 5vh;
  background-color: #f6f4fe;
}

/* Header styles */
.carousel-header {
  margin-bottom: 54px;
  margin-top: 34px;
  text-align: center;
}

/* Header box styles */
.header-box {
  line-height: 53px;
  font-size: 36px;
  color: rgb(0, 0, 0);
  text-shadow: .75px .75px .75px rgba(0,0,0,.15), .75px .75px .75px rgba(0,0,0,.15);
}

/* Two columns styles */
.columns-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 10%;
  margin-right: 10%;
}

.carousel-left-column {
  width: 41%;
  touch-action: pan-x;
  margin-left: 10%;
}

.carousel-item {
  height: 85px;
  align-items: center;
  background: #fff;
  border: 1px solid rgba(55,71,79,.15);
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  transition: height 1s ease-in-out;
  margin-bottom: 15px;
  padding: 0 24px;
  width: 400px;
  color: rgba(41,45,50,.6);
}

.carousel-item-number {
  font-weight: bold;
  margin-right: 8px;
  background-color: #d9d9d9; /* Default color for inactive */
  color: #292d32;
  align-items: center;
  border-radius: 50%;
  display: flex;
  font-size: 1.15vw;
  height: 33px;
  justify-content: center;
  width: 33px;
  transition: background-color .2s ease, color .5s ease;
}

.carousel-item:hover .carousel-item-number,
.carousel-item.active .carousel-item-number {
  background-color: #654ae9; /* Active color on hover and active state */
  color: #fff;
}

/* CSS right column start -------------> */
.carousel-right-column {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  display: block;
  width: 50.5%;
}

.carousel-right-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: left;
  justify-content: left;
  box-sizing: content-box;
  transition: transform 0.5s ease;
}

/* Image placeholder styles */
.image-placeholder {
  width: 575px;
  height: 575px;
  overflow: hidden;
  transition: transform 0.5s ease;
}

.image-placeholder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
  transform: translateX(0);
}

/* Apply a class to trigger the transition when the feature changes */
.carousel-right-wrap.image-transition .image-placeholder img {
  transform: translateX(100%);
}

/* Arrows container styles */
.arrows-container {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 50.5%;
  z-index: 2;
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 0;
  justify-content: center;
  line-height: 0;
  margin: 0;
  display: none;
}

/* Arrow icon styles (adjust as needed) */
.arrow-icon {
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease;
  border-radius: 50%;
  text-align: center;
  background-color: #0068ff;
  padding: 8px;
}

.arrow-icon.prev-button {
  left: 0 !important;
}

.arrow-icon.next-button {
  right: 0 !important;
}

.arrow-icon:hover {
  color: #ffffff;
  cursor: pointer;
  background-color: #74a6f2;
}

/* Footer styles ------------------- */
.footer-wrapper {
  padding: 0 120px;
  padding-top: 70px;
}

.location-area {
  background-color: #654ae9;
  border-radius: 32px;
  color: #fff;
  overflow: hidden;
  position: relative;
}

.image-box-for-pc {
  position: relative;
}

.image-box-for-pc img {
  height: auto;
  width: 100%;
  z-index: 2;
  transition: opacity 1s ease-in-out;
}

.app-download-button-container {
  position: absolute;
  top: 50%;
  right: 7.78%;
  transform: translateY(-50%);
  transition: left 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.app-download-button-container a {
  display: flex;
  flex-direction: column;
  height: 25px;
  margin-bottom: 45px;
  width: 100%;
  padding: 10px 30px;
  font-size: 16px;
  background-color: #ff4081; 
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.app-download-button-container a:hover {
  background-color: #e10050; 
}

/* Sales pitch styles */
.text-box {
  position: absolute;
  top: 50%;
  left: 7.78%;
  transform: translateY(-50%);
  transition: left 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.text-box .title {
  transition: all 1s ease-in-out;
}

.text-box .text {
  font-size: 22px;
  line-height: 32px;
  transition: all 1s ease-in-out;
}