/* MeetTheTeam.css */

/* Container styles */
.meet-the-team-container {
    text-align: center;
    padding: 5vh;
    background-color: #f8f9fa; /* Light background color */
    color: #333; /* Dark text color */
}
  
  /* Header styles */
  .meet-the-team-header {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  /* Team member styles */
  .team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .team-member {
    max-width: 300px;
    margin: 20px;
    padding: 20px;
    background-color: #fff; /* White background */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
  }
  
  .member-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .role {
    font-style: italic;
    color: #6c757d; /* Gray text color */
  }
  
  .bio {
    margin-top: 10px;
  }