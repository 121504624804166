/* Testimonials.css */

/* Container styles */
.testimonials-container {
  background-color: #654ae9;
  border-radius: 64px;
  margin-bottom: 80px;
  padding: 50px 30px 50px 30px;
}

.testimonials-container .title-area .title {
  color: #fff;
}

/* Slider styles */
.slider-wrap {
  position: relative;
  overflow: hidden;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.pagination-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color:#74a6f2;
  margin: 0 15px;
  cursor: pointer;
}

.pagination-circle.active {
  background-color:  #fff;
}

.arrow-icon {
  font-size: 18px;
  width: 24px;
  height: 24px;
  color: #0068ff;
  cursor: pointer;
  background-color: #fff;
  padding: 8px;
  border-radius: 50%;
  margin: 0 10px;
  z-index: 1;
}

.arrow-icon:hover {
  color: #ffffff;
  background-color: #74a6f2;
}

.slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
  margin-bottom: 50px;
}

.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  width: 334.4px;
  margin-right: 20px;
  background-color: #fff;
  border-radius: 25px;
}

.slide-content {
  padding: 20px;
  text-align: center;
}

.slide img {
  height: 120px;
  max-width: 280px;
}

.slide p {
  margin-bottom: 10px;
}

.name {
  font-weight: bold;
}

.address {
  color: #888;
}