/* Waves.css */
  .waves-container {
    background: url('../assets/bg-2.jpeg');
    display: flex;
  }

  .waves {
    position: relative;
    margin-bottom: -10px;
    height: 15vh;
    min-height: 100px;
    max-height: 150px;
    margin-top: -5%;
    width: 100%;
  }
  
  :not(svg) {
    transform-origin: 0px 0px;
  }
  
  .parallax>use {
    animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
  }
  
  .parallax>use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  
  .parallax>use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  
  .parallax>use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 11s;
  }
  
  .parallax>use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 13s;
  }

  @keyframes move-background {
    0% {
      transform: translateX(0, 0);
    }
    50% {
      transform: translateX(0, 0);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  @keyframes move-forever {
    0% {
        transform: translateX(0, 0);
      }
      100% {
        transform: translateX(100%);
      }
  }