/* DownloadApp.css */

/* Container styles */
.download-app-container {
    text-align: center;
    padding: 5vh;
    background-color: #393e46; /* Dark background color */
    color: #fff; /* White text color */
  }
  
  /* Header styles */
  .download-app-header {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  /* App description styles */
  .download-app-description {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  /* App download button styles */
  .app-download-buttons {
    display: flex;
    justify-content: space-around;
  }
  
  .app-download-button {
    background-color: #61dafb; /* Blue color for iOS */
    color: #fff;
    font-size: 16px;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .app-download-button.android {
    background-color: #4caf50; /* Green color for Android */
  }
  
  /* Hover effect for app download buttons */
  .app-download-button:hover {
    background-color: #00a0ff; /* Lighter blue on hover */
  }