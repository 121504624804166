/* Footer.css */

/* Container styles */
.footer-container {
    background-color: #583bd3; /* Dark background color */
    color: #e5e5e5; /* Light text color */
    padding: 20px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  /* Footer section styles */
  .footer-section {
    max-width: 250px;
    margin-bottom: 20px;
  }
  
  .footer-section h4 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #61dafb; /* Blue heading color */
  }
  
  .footer-section p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  /* Footer navigation styles */
  .footer-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 5px;
  }
  
  .footer-section ul li a {
    color: #61dafb; /* Blue link color */
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .footer-section ul li a:hover {
    color: #00a0ff; /* Lighter blue on hover */
  }
  
  /* Social icons styles */
  .social-icons {
    display: flex;
  }
  
  .footer-bottom {
    padding: 20px 0;
  }

/* New section styles */
.footer-section.copyrights {
  min-width: 88vw; /* Set to full viewport width */
  background-color: #654ae9; /* Purple background color */
  padding: 10px;
  color: #fff; /* White text color */
  font-weight: bold;
  text-align: left;
  padding-left: 10%;
}

.footer-section.copyrights h4 {
  font-size: 20px;
  margin-bottom: 10px;
}
