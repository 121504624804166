.features-section {
    background: #f6f4fe;
    text-align: center;
    padding: 10vh;
}

.features-section-title {
    margin-bottom: 10px;
}

.features-title {
    max-width: 960px;
    font-size: 40px;
}

.features-title {
    color: #654ae9;
}

.features-title-description {
    max-width: 692px;
    margin-top: 0px;
}

.feature-row {
    padding: 48px 0;
    flex-wrap: nowrap;
    gap: 48px;
    justify-content: center;
}

.fade-left img {
    width: 100%;
    max-width: 250px;
    border-radius: 20px;
    box-shadow: 0 0 20px #654ae955;
    vertical-align: middle;
    border-style: none;
}

.row-content {
    text-align: left;
    max-width: 280px;
}

.row-content h2 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 15px;
}

.row-content span {
    color: #654ae9;
}

.row-content-description {
    margin-top: 0;
    margin-bottom: 1rem;
}