/* FAQ.css */

/* Container styles */
.faq-container {
  text-align: left;
  margin-bottom: 20px;
}

/* Header styles */
.faq-header {
  font-size: 40px;
  font-weight: 700;
  padding-bottom: 20px;
  text-align: center;
}

/* FAQ item styles */
.faq-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faq-item {
  padding: 0;
  color: #32236f;
  font-weight: 600;
  font-size: 20px;
  width: 80%;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
  transition: background-color 0.3s ease;
}

.faq-item.active {
  background-color: #FFF; /* Blue color when active */
}

.question {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  font-size: 20px;
  cursor: pointer;
  background-color: #FFF; /* Dark background color */
}

.icon {
  font-size: 20px;
}

.answer {
  padding: 1.25rem;
  font-size: 16px;
  color: #3e3f66;
  background-color: #FFF; /* Blue color for answer background */
  transition: max-height 0.4s ease, opacity 0.4s ease;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
}

.faq-item.active .answer {
  max-height: 500px; /* Adjust this value based on your content height */
  opacity: 1;
}